// All server side options and date opration perform here

import moment from "moment";
import { useMatches } from "react-router-dom";

import { privateRequest } from "../api/axiosConfig/privateRequest";

import { toast } from "react-toastify";

export const configToast = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const showSuccessNotification = (message, configToast) => {
  return toast.success(message, configToast);
};

export const showErrorNotification = (message, configToast) => {
  return toast.error(message, configToast);
};

export const showDeleteNotification = (message, configToast) => {
  return toast.success(message, configToast);
};

// taking input as '2021-12-03' output will be in 0.5 year, 1 year, 2 year and so on
export const calculateDateToString = (date) => {
  const exp_date = moment().diff(new Date(date), "month");
  const difference = (exp_date / 12).toString();
  return difference.substring(difference.length - 2, difference.length) === ".5"
    ? exp_date / 12
    : Math.round(exp_date / 12);
};

// take input as 0.5 year and return '2021-12-03' from today
export const calculateStringToDate = (yearOfExp) => {
  const monthsBack = parseInt(yearOfExp.split(" ")[0] * 12);
  const exp = new Date(moment().subtract(monthsBack, "months"));
  return moment(exp).format("YYYY-MM-DD");
};

export const formatDate = (date) => {
  const convert = new Date(date);
  return moment(convert).format("YYYY-MM-DD");
};

// options

// technologyOptions are for => languages and otherlanguages
export const technologyOptionsUtility = async (label_name, value_name) => {
  try {
    const { data: tech } = await privateRequest("/technologies?perPage=10000");
    const tOptions = tech?.data?.results?.map((t) => {
      return {
        label: t[label_name],
        value: `${t[value_name]}`,
      };
    });
    return tOptions;
  } catch (error) {
    console.log('error', error)
  }
};

export const vendorOptionsUtility = async (label_name, value_name) => {
  try {
    const { data: vend } = await privateRequest("/vendors?perPage=10000");
    const vOptions = vend?.data?.results?.map((t) => {
      return {
        label: t[label_name],
        value: `${t[value_name]}`,
      };
    });
    return vOptions;
  } catch (error) {
    console.log('error', error.message)
  }
};

export const externalResourceOptionsUtility = async () => {
  try {
    const { data: exRes } = await privateRequest("/external-resource?perPage=10000");
    const exOptions = exRes?.data?.externalResource?.map((t) => {
      return {
        label: `${t.fname} ${t.lname}`,
        value: `${t.id}`,
      };
    });
    return exOptions;
  } catch (error) {
    console.log('error', error.message)
  }
};

export const externalResourceByDefaultValuesUtility = async (value) => {
  try {
    const { data: exRes } = await privateRequest(`/external-resource/${value}`);
    const { fname, lname, email, phone } = exRes?.data?.externalResource;
    return { fname, lname, email, phone };
  } catch (error) {
    console.log('error', error.message)
  }
};

export const resourceByDefaultValuesUtility = async (value) => {
  try {
    const { data: resRes } = await privateRequest(`/resource/${value}`);
    const { email } = resRes?.data?.resource;
    const resources_email = email;
    return { resources_email };
  } catch (error) {
    console.log('error', error.message)
  }
};

export const joiningByDefaultValuesUtility = async (value) => {
  try {
    const { data: joinRes } = await privateRequest(`/joinings/${value}`);
    const { client_id, client, resource_id } =
      joinRes?.data;
    const clients = client.client_name;
    return { clients, resource_id, client_id };
  } catch (error) {
    console.log('error', error.message)
  }
};

export const clientByDefaultValuesUtility = async (value) => {
  try {
    const { data } = await privateRequest(`/clients/${value}`);
    const contactsByType = data?.data?.client?.contacts?.reduce((acc, contact) => {
      const { contact_type, name, email, mobile } = contact;
      acc[`${contact_type.toLowerCase()}_name`] = name;
      acc[`${contact_type.toLowerCase()}_email`] = email;
      acc[`${contact_type.toLowerCase()}_mobile`] = mobile;

      return acc;
    }, {})
    const {
      report_name,
      report_email,
      report_mobile,
      finance_name,
      finance_email,
    } = contactsByType;
    const { address } = data?.data?.client
    return {
      report_name,
      report_email,
      report_mobile,
      finance_name,
      finance_email,
      address,
    };
  } catch (error) {
    console.log('error', error.message)
  }
};

export const clientOptionsUtility = async () => {
  const { data: clientRes } = await privateRequest("/clients?perPage=10000");
  try {
    const clOptions = clientRes?.data?.result?.map((t) => {
      return {
        label: t.client_name,
        value: t.id,
        address: t.address
      };
    });
    return clOptions;
  } catch (error) {
    console.log('error', error.message)
  }

};

//Joining Option Utlity
export const joiningOptionsUtility = async () => {
  try {
    const { data: joinRes } = await privateRequest("/joinings?perPage=10000")
    const joinOptions = joinRes?.data?.results?.map((t) => {
      return {
        label: `${t?.resource?.fname} ${t?.resource?.lname}`,
        value: { joining_id: t.id, resource_id: t.resource_id }
      };
    });
    return joinOptions;
  } catch (error) {
    console.log(error)
  }
};

export const reqclientOptionsUtility = async (label_name, id) => {
  try {
    const { data: rclientRes } = await privateRequest("/clients?perPage=10000");
    const rclOptions = rclientRes?.data?.result?.map((t) => {
      return {
        id: t[id],
        label: t[label_name],
        value: `${t[id]}`,
      };
    });
    return rclOptions;
  } catch (error) {
    console.log('error', error.message)
  }
};

export const resourceOptionsUtility = async () => {
  try {
    const { data: resRes } = await privateRequest("/resource?perPage=10000");
    const resOptions = resRes?.data?.data?.map((t) => {
      return {
        label: t.fname + " " + t.lname,
        value: t["id"],
      };
    });

    return resOptions;
  } catch (error) {
    console.log('error', error.message)
  }
}

export const reasonOptionsUtility = async () => {
  try {
    const { data: resRes } = await privateRequest("/reason?perPage=10000");

    const resOptions = resRes?.data?.result.map((t) => {
      return {
        label: `${t.description}`,
        value: `${t.description}`,
      };
    });
    return resOptions;
  } catch (error) {
    console.log('error', error.message)
  }

};

export const nicknameOptionsUtility = async (value_name) => {
  try {
    const { data: resRes } = await privateRequest("/resource?perPage=10000");
    const resOptions = resRes?.data?.data.map((t) => {
      const vendorName = t.vendorData ? t.vendorData.company_name : "";
      return {
        label: vendorName,
        value: t[value_name],
      };
    });
    return resOptions;
  } catch (error) {
    console.log('error', error.message)
  }
};

export const externlaProductUtility = async () => {
  try {
    const { data: resProduct } = await privateRequest("/external-products?perPage=10000");
    const exPOptions = resProduct?.data?.result?.map((t) => {
      return {
        label: `${t.name}`,
        value: `${t.name}`,
      };
    });
    return exPOptions;
  } catch (error) {
    console.log('error', error.message)
  }
};

// experience options
export const experienceOptions = [
  { label: "0.5 year", value: "0.5 year" },
  { label: "1 year", value: "1 year" },
  { label: "1.5 year", value: "1.5 year" },
  { label: "2 year", value: "2 year" },
  { label: "2.5 year", value: "2.5 year" },
  { label: "3 year", value: "3 year" },
  { label: "3.5 year", value: "3.5 year" },
  { label: "4 year", value: "4 year" },
  { label: "4.5 year", value: "4.5 year" },
  { label: "5 year", value: "5 year" },
  { label: "5.5 year", value: "5.5 year" },
  { label: "6 year", value: "6 year" },
  { label: "6.5 year", value: "6.5 year" },
  { label: "7 year", value: "7 year" },
  { label: "7.5 year", value: "7.5 year" },
  { label: "8 year", value: "8 year" },
  { label: "8.5 year", value: "8.5 year" },
  { label: "9 year", value: "9 year" },
  { label: "9.5 year", value: "9.5 year" },
  { label: "10 year", value: "10 year" },
  { label: "10.5 year", value: "10.5 year" },
  { label: "11 year", value: "11 year" },
  { label: "11.5 year", value: "11.5 year" },
  { label: "12 year", value: "12 year" },
  { label: "12.5 year", value: "12.5 year" },
];

export const scheduleByOptionsUtility = async (
  name,
  id,
) => {
  try {
    const { data: schedRes } = await privateRequest("/users?perPage=10000");
    const sOptions = schedRes?.data?.results
      .filter((t) => t.name !== null)
      .map((t) => {


        return {
          label: t[name],
          value: t[id],
        };
      });
    return sOptions;
  } catch (error) {
    console.log('error', error.message)
  }
};

// Resource Option
export const LoadResourceOptions = async (search) => {
  try {
    const { data: resRes } = await privateRequest(`/resource?perPage=20&search=${search}`);
    const resOptions = resRes?.data?.data?.map((t) => {

      return {
        label: t.fname + " " + t.lname,
        value: t["id"],
        email: t.email,
        vendorName: t?.vendorData?.company_name,
        vendor_id: t.vendor_id,
        isExternal: t.isExternal
      };
    });
    return resOptions;
  } catch (error) {
    console.log('error', error.message)
  }
}

export const LoadExResourceOptions = async (search) => {
  try {
    const { data: resRes } = await privateRequest(`/external-resource?perPage=20&search=${search}`);
    const resOptions = resRes?.data?.data?.map((t) => {

      return {
        label: t.fname + " " + t.lname,
        value: t["id"],
        email: t.email,
        vendorName: t?.vendorData?.company_name,
        vendor_id: t.vendor_id
      };
    });
    return resOptions;
  } catch (error) {
    console.log('error', error.message)
  }
}

//vendorr
export const LoadVendorOptions = async (search) => {
  try {
    const { data: vend } = await privateRequest(`/vendors?perPage=20&search=${search}`);
    const vOptions = vend?.data?.results?.map((t) => {
      return {
        label: t.company_name,
        value: t.id,
      };
    });
    return vOptions;
  } catch (error) {
    console.log('error', error.message)
  }
};

// client option

export const LoadClientOptions = async (search) => {
  const { data: clientRes } = await privateRequest(`/clients?perPage=20&search=${search}`);
  try {
    const clOptions = clientRes?.data?.result?.map((t) => {
      const contactsByType = t.contacts?.reduce((acc, contact) => {
        const { contact_type, name, email, mobile } = contact;
        acc[`${contact_type.toLowerCase()}_name`] = name;
        acc[`${contact_type.toLowerCase()}_email`] = email;
        acc[`${contact_type.toLowerCase()}_mobile`] = mobile;

        return acc;
      }, {})
      const {
        report_name,
        report_email,
        report_mobile,
        finance_name,
        finance_email,
      } = contactsByType;
      return {
        label: t.client_name,
        value: t.id,
        address: t.address,
        report_name,
        report_email,
        report_mobile,
        finance_name,
        finance_email,
      };
    });
    return clOptions;
  } catch (error) {
    console.log('error', error.message)
  }
};

// Schedule by option
export const LoadScheduleByOptions = async (
  search,
) => {
  try {
    const { data: schedRes } = await privateRequest(`/users?perPage=20&search=${search}`);
    const sOptions = schedRes?.data?.results
      .filter((t) => t.name !== null)
      .map((t) => {

        return {
          label: t?.name,
          value: t?.id
        };
      });
    return sOptions;
  } catch (error) {
    console.log('error', error.message)
  }
};

// Mode Of Interview
export const modeOfInterviewOptions = [
  { label: "Telephonic", value: "Telephonic" },
  { label: "F2F", value: "F2F" },
  { label: "Skype", value: "Skype" },
  { label: "Google Hangout", value: "Google Hangout" },
  { label: "Zoom ", value: "Zoom" },
  { label: "Google Meet", value: "Google Meet" }
];

//Interview location
export const interviewLocationOptions = [
  { key: "Client Office", value: "Client Office" },
  { key: "Nimap Office", value: "Nimap Office" },
  { key: "Home", value: "home" },
];

export const externalAndInternalOptions = [
  { key: "External", value: true },
  { key: "Internal", value: false },
];

export const resumeOptions = [
  { key: "File", value: "file" },
  { key: "Link", value: 'link' }
]


//Interview Type
export const interviewTypeOptions = [
  { key: "L1", value: "L1" },
  { key: "L2", value: "L2" },
  { key: "Screening Round", value: "Screening Round" },
  { key: "Test", value: "Test" },
  { key: "Final", value: "Final" },
];

//l1 types
export const l1TypeOption = [
  { key: "L1", value: "L1" },
];

//for making first letter capital 
export const firstLetterCapital = (word) => {
  if (word) {
    const firstLetter = word.charAt(0).toUpperCase()
    const wordToChar = word.split("")
    return firstLetter.concat(wordToChar.slice(1).join(""))
  }
  return ""

}

// invoice date 
export const dateOfInvoiceOptions = [
  { label: "1st Of Months", value: "1" },
  { label: "15th Of Months", value: "15" },
  { label: "30th Of Months", value: "30" },
  { label: "1st Of Next Months", value: "31" },
  { label: "15th Of Next Months", value: "45" },
  { label: "30th Of Next Months", value: "60" },
];


export const exportInvoiceStatusOptionsGen = () => {
  const currentDate = moment()
  const options = [{
    label: currentDate.format('MMMM-YYYY'),  
    value: currentDate.format('MM-YYYY')  
  }];
  for (let i = 0; i < 12; i++) {
    const month = currentDate.subtract(1, 'months');  // Subtract 1 month on each iteration
    options.push({
      label: month.format('MMMM-YYYY'),  // Format it like "January-2024"
      value: month.format('MM-YYYY')     // Format it like "01-2024"
    });
  }

  return options
}

export const exportInvoiceStatusOptions = exportInvoiceStatusOptionsGen();

export const capitalizeString = str => {
  return str.split(" ").map(x => x[0]?.toUpperCase() + x.slice(1)).join(" ")
}

export const getCurrentRoleId = () => {
  return JSON.parse(localStorage.getItem("authUser"))?.roleId
}

export const getCurrentRoleName = () => {
  return JSON.parse(localStorage.getItem("authUser"))?.role?.roleName
}

export const getCurrentPagePermissions = (permissions, moduleName, entityName) => {
  return permissions?.find(moduleData => moduleData?.moduleName === moduleName)
    ?.entity?.find(entity => entity?.entityName === entityName)
    ?.permission?.reduce((acc, permission) => {
      acc[permission?.actionName] = permission?.isSelected
      return acc
    }, {})
}

export const getChangedValues = (values, initialValues) => {

  const data = { ...values };
  const keyValues = Object.keys(data);

  const dirtyValues = keyValues.filter(
    (keyValue) => data[keyValue] !== initialValues[keyValue],
  );

  keyValues.forEach((key) => {
    if (!dirtyValues.includes(key)) delete data[key];
  });

  return data;
}

export const transformInvoiceData = (data, months) => {
  const result = [];

  data.forEach(item => {
    let client = result.find(c => c.client_id === item.client_id);

    if (!client) {
      client = {
        client_id: item.client_id,
        client_name: item.client.client_name,
        credit_experience: item.client.credit_experience,
        credit_period: item?.client?.credit_period,
        invoice_date: item?.client?.invoice_date,
        months: []
      };
      result.push(client);
    }

    if (months.includes(item.month)) {
      client.months[months.indexOf(item.month)] = {
        client_id: item.client_id,
        countResource: item.countResource,
        month: item.month,
        year: item.year,
        status: item.status,
        hard_copy: item.hard_copy,
        payment: item.payment,
        invoice: item.invoice,
        resouceData: item.resouceData
      }
    }
  });

  return result;
}

export const removeEmptyOrNullValues = (values) => {
  const data = { ...values }

  Object.keys(values).forEach(key => {
    if (data[key] === '' || data[key] === null) {
      delete data[key];
    }
  });
  return data;
}


export const combineClasses = (...classes) => {
  return classes.join(" ");
};

export const checkRoutePermission = async (permission) => {
  if (!permission) {
    return true
  }
  let loggedInPermissions = await JSON.parse(localStorage.getItem("authUser"))?.permissions || []
  loggedInPermissions = Object.keys(loggedInPermissions)
  return loggedInPermissions.includes(permission)
}



export const useRouteMetadata = () => {
  const matches = useMatches();  // Get all matched routes
  // Loop through the matches and find the relevant metadata (like permissionName)
  const route = matches[matches.length - 1];  // Get the last matched route
  return route?.handle?.pageName;  // Assuming permissionName is stored in handle
}


export const fileMimeTypeValidation = (value) => {
  if (value == null || typeof (value) === "string") return true;
  return value && value.type == "application/pdf";
}

export const fileSizeValidation = (value, limit = 5) => {
  return !value || typeof (value) === "string" || value.size <= 1048576 * limit
}